* {
    font-family: 'MonumentExtended regular', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0px;
    text-decoration: none;
}

html, body {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    overflow-x: hidden;
    user-select: none;
    background-image: url('https://cdn.fiebrefiesta.com.ar/textura.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    
    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
    }

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
}

button {
    border: none;
    background-color: transparent;
}

main {
    width: 100%;
}

.mt-1 {
    margin-top: 5px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-5 {
    margin-top: 20px;
}

.mb-1 {
    margin-bottom: 5px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-5 {
    margin-bottom: 20px;
}

.fs-1 {
    font-size: 10px;
}

.fs-2 {
    font-size: 12px;
}

.fs-3 {
    font-size: 14px;
}

.fs-4 {
    font-size: 16px;
}

.fs-5 {
    font-size: 18px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.bold {
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
}

@font-face {
    font-family: 'MonumentExtended regular';
    src: url('../../fonts/MonumentExtended-Regular.otf') format('truetype');
}

.toast-title {
    font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
